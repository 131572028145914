import { Component, Vue } from 'vue-property-decorator'
import FormLegal from '@/components/LegalForm/Legal/Legal.vue'
import Legal from '@/Models/Legal'
import ModalDelete from '@/components/ModalDelete/ModalDelete.vue'
import http from '@/services/http'
import Search from '@/components/Search/Search.vue'
import router from '@/router'
import * as Helpers from '@/helpers'
import Header from '@/components/Header/Header.vue'
import menuModule from '@/store/menu'

import Paginator from '@/components/Paginator/Paginator.vue'
import Item from '@/Models/Item'
import Animation from '@/components/Animation/index.vue'

@Component({
  components: {
    FormLegal,
    Search,
    ModalDelete,
    Header,
    Paginator,
  },
})
export default class ViewLegal extends Vue {
  showList = true
  data: Array<Legal> = []
  objLegal: Legal = new Legal()

  isAction = 'add'
  loading = false
  delete_modal_active = false

  url = 'api/legal/arcr_legal/'
  params: Array<Item> = []

  perPage = menuModule.perPage_param

  header: Array<any> = [
    {
      field: 'client_member_number',
      label: '# miembro',
    },
    {
      field: 'name_client',
      label: 'Nombre cliente',
    },
    {
      field: 'court_name',
      label: 'Nombre juzgado',
    },
    {
      field: 'file_number',
      label: 'Número expediente',
    },
    {
      field: 'observation',
      label: 'Comentarios',
    },
  ]

  editObj(props: any) {
    this.objLegal = props
    this.showList = false
    this.isAction = 'edit'
  }
  async deleteObj() {
    try {
      await http.delete(`api/legal/arcr_legal/${this.objLegal.id}/`)
      this.data = this.data.filter((a: Legal) => a.id !== this.objLegal.id)
      this.delete_modal_active = false
    } catch (error) {
      console.log(error)
      //toast.error("An error has occurred please try again.")
    }
  }
  changePerPage(range_perPage: any) {
    menuModule.setPerpageParam(String(range_perPage))
    this.perPage = menuModule.perPage_param
  }

  deleteConfirm(props: any) {
    this.delete_modal_active = true
    this.objLegal = props
  }
  closeModal() {
    this.objLegal = new Legal()
    this.delete_modal_active = false
  }
  Observations(props: any) {
    this.objLegal = props
    const id = this.objLegal.id.toString()
    menuModule.setIdParam(id)
    router.push({ name: 'LegalObservation' })
  }
  document(props: any) {
    this.objLegal = props
    const id = this.objLegal.id.toString()
    menuModule.setIdParam(id)
    router.push({ name: 'LegalDocument' })
  }
  openForm() {
    this.showList = false
  }
  async cleanForm() {
    this.objLegal = new Legal()
    this.showList = true
    this.isAction = 'add'
    ;(this.$refs.header as any).showList = true
  }
  filterItems(items: any) {
    this.data = items.map((obj: any) => ({
      ...obj,
      name_client: obj.member_number.client_name,
      client_member_number: obj.member_number.member_number,
      date_registered: Helpers.dateParse(obj.date_registered),
    }))
  }
  async SearchFilter(search: string) {
    this.params = [] // se limpia el arreglo de params
    this.params.push({
      text: 'search', // nombre del parametro que se agrega a la url
      value: search, // valor del parametro
    })
    const currentPage = await (this.$refs['Paginator'] as any)['current'] //Se guarda la página actual
    await (this.$refs['Paginator'] as any).paginate(currentPage) // se ejecuta el metodo de paginar sobre la misma página actual pero ahora con los debidos parametros extras
  }
}
